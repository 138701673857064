import React, { useState } from 'react'
import './LearningPartners.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import user from "../../../assets/user.png"
const LearningPartners = () => {
  const slideLeft = () => {
    var slider = document.getElementById('learningPartnerSlider')
    windowDimensions.width >= 750
      ? (slider.scrollLeft = slider.scrollLeft - 730)
      : (slider.scrollLeft = slider.scrollLeft - 100)
    //console.log(slider);
  }
  const slideRight = () => {
    var slider = document.getElementById('learningPartnerSlider')
    windowDimensions.width >= 750
      ? (slider.scrollLeft = slider.scrollLeft + 730)
      : (slider.scrollLeft = slider.scrollLeft + 100)
  }
  const hasWindow = typeof window !== 'undefined'
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  function getWindowDimensions () {
    const width = hasWindow ? window.innerWidth : null
    return {
      width
    }
  }
  React.useEffect(() => {
    if (hasWindow) {
      function handleResize () {
        setWindowDimensions(getWindowDimensions())
      }
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])
  const trainingPartners = [
    {
      url: user,
      name: 'Ali Raza',
      designation: 'General Manager HR',
      details:'Ali has been associated with M&P since 2013. He has experience in different facets of HR but Organizational Development remains his forte. Before joining M&P, he served at Citibank for 7.5 years in Human Resources.'
    },
    {
      url: user,
      name: 'Niaz Malik',
      designation: 'Ex Deputy CEO Zong',
      details:'Over 25 years of successful operating experience in marketing, branding, technology and telecommunications, Niaz Malik has done it all. He has the ability to create a strategy and translate it into actions across all departments. Currently he is the Brand Ambassador for Road Safety at Indus Motor Company Ltd.'
    },
    {
      url: user,
      name: 'Mian Atif Iqbal',
      designation: '',
      details:'Atif is a committed resource having over 22 years of diversified & multicultural experience in the field of HRM. He has also worked with Unilever Pakistan Ltd. and Dalda Foods Pvt. Ltd. as an HR Manager.'
    },
    {
      url: user,
      name: 'Tayyab Iqbal',
      designation: '',
      details:'Tayyab has been associated with M&P since 2015. He is also a certified ISO professional. He has over 14 years of experience. Before joining M&P he has worked in different Quality Management and Assurance Roles in companies like DADEX, PICTL, TCS and Agility.'
    },
    {
      url: user,
      name: 'Imran Mazhar',
      designation: 'Talent & Development Manager',
      details:'Having comprehensive experience of over 15 years, Imran understands the needs of our organization considering the aspects of recruitment, training and organizational development. He started off his professional journey with Mobilink where he served 10 years.'
    },
    {
      url: user,
      name: 'Jahangir Sachwani',
      designation: '',
      details:'Jahangir has been related to the profession of Training & Development since 1998 and has conducted many programs and seminars for varied audiences. Currently, he is a certified Microsoft Power BI Trainer and a vivacious professional.'
    },
    {
      url: user,
      name: 'Maalik Khaskhely',
      designation: '',
      details:'Maalik Khaskhely has trained thousands towards a better and a sustainable future. As a Lead Trainer, his interactive sessions are an amalgamation of energizing ice-breakers, fruitful discussions, intellectual dialogue, and creative solutions. Being the executive director for SoL (School of Leadership) he has led several projects like Active Citizens (British Council) and Sehatmand Pakistan (UNICEF) etc.'
    },
    {
      url: user,
      name: 'Farhan Ali Jamali',
      designation: '',
      details:'As a certified success coach, instructional designer, trainer, facilitator and an eLearning expert with over 13 years of experience, Farhan brings a wealth of expertise in Life & Business Coaching and Instructional Design. He has worked with a diverse range of global coaching clients, delivering impactful and engaging programs that drive sustainable change.'
    },
    {
      url: user,
      name: 'Tabish Hassan Khan',
      designation: '',
      details:'Tabish Hassan Khan has a comprehensive experience of over 8 years with a proven record of success. Currently he is working with us as HRBP for the Telecom & Allied Business Division and has delivered multiple sessions on Corporate Code of Ethics and Whistle Blowing Policies within M&P.'
    },
    {
      url: user,
      name: 'Muhammad Owais',
      designation: '',
      details:'Muhammad Owais has been associated with M&P since 2017 and is currently working as our HRBP for the Pharma and Logistics Division. He is our SME for MS Excel along with a good command over HR Operations and Management Reports Compensation Tools.'
    },
    {
      url: user,
      name: 'Ayaz Sario',
      designation: '',
      details:'An experienced professional with over a decade experience in teaching ESL Classes, IELTS and other Advanced Business Communication courses to diverse participants from academia and corporate backgrounds. He has worked with multiple renowned local and international institutes pertaining to teaching English as a Secondary Language.'
    },
    {
      url: user,
      name: 'Syed Faisal Raza',
      designation: '',
      details:'Syed Faisal Raza is working for us as a Business Unit Head in our Telecom Division. Being associated with M&P for over 5 years, his passion for training and development of employees has been instrumental in passing his knowledge onto others.'
    }
  ]

  return (
    <div className='learning__partner'>
      <div className='learning__partner__heading'>Our Learning Partners</div>
      <div className='learning__partner__card__btn__box'>
        {windowDimensions.width >= 750 ? (
          <div className='learning__partner__btn' onClick={slideLeft}>
            <ArrowBackIosNewIcon sx={{ color: 'black' }} />
          </div>
        ) : null}
        <div
          id='learningPartnerSlider'
          className='learning__partner__cards__container'
        >
          {trainingPartners.map((cur, ind) => (
            <div className='learning__partner___card' key={ind}>
              <div className='card__img'>
                <img className='card__img' src={cur.url} />
              </div>
              <div className='card__name'>{cur.name}</div>
              <div className='card__designation'>{cur.designation}</div>
              <div className='card__details'>{cur.details}</div>
            </div>
          ))}
        </div>
        {windowDimensions.width >= 750 ? (
          <div className='learning__partner__btn' onClick={slideRight}>
            <ArrowForwardIosIcon sx={{ color: 'black' }} />
          </div>
        ) : null}
      </div>

      <div className='learning__partner__btn__box'>
        {windowDimensions.width <= 750 ? (
          <div className='learning__partner__btn' onClick={slideLeft}>
            {<ArrowBackIosNewIcon sx={{ color: 'black' }} />}
          </div>
        ) : null}
        {windowDimensions.width <= 750 ? (
          <div className='learning__partner__btn' onClick={slideRight}>
            <ArrowForwardIosIcon sx={{ color: 'black' }} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default LearningPartners
