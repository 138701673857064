import React from 'react'
import './UpcomingTrainings.css'
import 'react-slideshow-image/dist/styles.css'
import background from '../../../assets/waveblue2.png'
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Image1 from '../../../assets/P1.jpg'
import Image2 from '../../../assets/P2.jpg'
import Image3 from '../../../assets/P3.jpg'

const UpcomingTrainings = () => {

  const navigate = useNavigate()
  const orangeTheme = createTheme({
    palette: { primary: { main: '#00a0ad' }, secondary: { main: '#41424C' } }
  })
  const training = [{ url: Image1, caption: 'Slide 1' }, { url: Image2, caption: 'Slide 2' }, { url: Image3, caption: 'Slide 3' }]
  
  return (
    <div
      className='training__section'
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'left',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <ThemeProvider theme={orangeTheme}>
        <div className='training__section__container'>
          <div className='training__section__heading'> Regularly Scheduled</div>
          <div className='training__slider__container'>
            <div className='training__content__box'>
              <div className='training__content__heading'>
                Upcoming Trainings
              </div>
              <div className='training__content__text'>
                To create and foster a learning environment, Continuous Learning
                is what paves the way. Virtual, in person, on the job, off the
                job, specialized assignments, role plays, and delegation all
                play their part in Development some way or the other. To create
                an Ongoing learning culture, new courses are introduced every
                month.
              </div>
              <div>
                <div
                  className='nomination__btn color'
                  onClick={() => navigate('/UserPanel/Trainings')}
                  variant='contained'
                >
                  View Trainings
                </div>
              </div>
            </div>
            <div className='slide-container'>
              <AwesomeSlider transitionDelay={1}>
                {training.map((slideImage, index) => (
                  <div>
                    <img className='slide-back' src={slideImage.url} alt={slideImage.caption} />
                  </div>
                ))}
              </AwesomeSlider>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </div>
  )
}

export default UpcomingTrainings
