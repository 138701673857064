import React, { useState } from "react";
import "./SignUp.css";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const SignUp = () => {
  let navigate = useNavigate();
  const orangeTheme = createTheme({
    palette: {
      primary: {
        main: '#00a0ad',
      },
      secondary: {
        main: '#41424C',
      },
    },
  });
  const [signUpData, setSignUpData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    occupation: "",
    organization: "",
  });
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => {
    setSignUpData({
      ...signUpData,
      showPassword: !signUpData.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => {
    setSignUpData({
      ...signUpData,
      showConfirmPassword: !signUpData.showConfirmPassword,
    });
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setSignUpData({ ...signUpData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validator.isEmail(signUpData.email)) {
      toast.warn("Incorrect Email. Please enter a valid Email");
    } else if (signUpData.password !== signUpData.confirmPassword) {
      toast.warn("Password not match");
    } else {
      setLoading(true);
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + "/api/users/registeruser",
          signUpData
        )
        .then((res) => {
          //console.log(res);
          if (res.data.error !== true) {
            toast.success(res.data.message);
            setSignUpData({
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              occupation: "",
              organization: "",
            });
            setTimeout(() => navigate("/login"), 4000);
          } else {
            toast.warn(res.data.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          err.response.data.code === 11000 &&
            toast.warn("User already register with this email");
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Header />
      <div className="signUp">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <h3>Register</h3>
        <form className="signUp__form" onSubmit={handleSubmit}>
          <div className="signUp__textfield">
            <FormControl className="Login__formControl">
              <InputLabel htmlFor="standard-adornment-password">
                First Name *
              </InputLabel>
              <Input
                id="standard-basic"
                label="First Name"
                variant="standard"
                name="name"
                value={signUpData.name}
                onChange={handleChange}
                required
                onKeyPress={(event) => {
                  if (/[^A-z\s]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </div>

          <div className="signUp__textfield">
            <FormControl className="Login__formControl">
              <InputLabel htmlFor="standard-adornment-password">
                Email *
              </InputLabel>
              <Input
                className="signUp__textfield"
                id="standard-basic"
                label="Email"
                variant="standard"
                name="email"
                InputProps={{ inputProps: { min: 6 } }}
                value={signUpData.email}
                onChange={handleChange}
                required
              />
            </FormControl>
          </div>

          <div className="signUp__textfield">
            <FormControl className="Login__formControl">
              <InputLabel htmlFor="standard-adornment-password">
                Occupation *
              </InputLabel>
              <Input
                className="signUp__textfield"
                id="standard-basic"
                label="Occupation"
                variant="standard"
                name="occupation"
                value={signUpData.occupation}
                onChange={handleChange}
                required
                inputProps={{ minLength: 4 }}
              />
            </FormControl>
          </div>
          <div className="signUp__textfield">
            <FormControl className="Login__formControl">
              <InputLabel htmlFor="standard-adornment-password">
                Organization *
              </InputLabel>
              <Input
                className="signUp__textfield"
                id="standard-basic"
                label="organization"
                variant="standard"
                name="organization"
                value={signUpData.organization}
                onChange={handleChange}
                required
              />
            </FormControl>
          </div>
          <div className="signUp__textfield">
            <FormControl className="Login__formControl">
              <InputLabel htmlFor="standard-adornment-password">
                Password *
              </InputLabel>
              <Input
                className="signUp__textfield"
                id="standard-adornment-password"
                type={signUpData.showPassword ? "text" : "password"}
                name="password"
                value={signUpData.password}
                required
                autoComplete="off"
                inputProps={{ minLength: 6 }}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {signUpData.showPassword ? (
                        <Visibility sx={{ color: "black" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="signUp__textfield">
            <FormControl className="Login__formControl">
              <InputLabel htmlFor="standard-adornment-password">
                Confirm Password *
              </InputLabel>
              <Input
                className="signUp__textfield"
                id="standard-adornment-password"
                type={signUpData.showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={signUpData.confirmPassword}
                required
                autoComplete="off"
                inputProps={{ minLength: 6 }}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {signUpData.showConfirmPassword ? (
                        <Visibility sx={{ color: "black" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div>
            <ThemeProvider theme={orangeTheme}>
              <LoadingButton
                className="signUp__btn"
                type="submit"
                loading={loading}

                variant="outlined"
              >
                Register
              </LoadingButton>
            </ThemeProvider>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default SignUp;
